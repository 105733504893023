import { render, staticRenderFns } from "./SiteHeader.vue?vue&type=template&id=385ad017&"
import script from "./SiteHeader.vue?vue&type=script&lang=ts&"
export * from "./SiteHeader.vue?vue&type=script&lang=ts&"
import style0 from "./SiteHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogotypeFull: require('/tmp/build_96a94860/src/components/misc/LogotypeFull.vue').default,NavigationLink: require('/tmp/build_96a94860/src/components/navigation/NavigationLink.vue').default,SiteHeaderSecondaryNavToggle: require('/tmp/build_96a94860/src/components/navigation/SiteHeader/SecondaryNavToggle.vue').default,MobileNavigationToggle: require('/tmp/build_96a94860/src/components/navigation/MobileNavigation/MobileNavigationToggle.vue').default,SiteHeaderSecondaryNav: require('/tmp/build_96a94860/src/components/navigation/SiteHeader/SecondaryNav.vue').default})
