import { render, staticRenderFns } from "./SiteFooter.vue?vue&type=template&id=8c334b02&"
import script from "./SiteFooter.vue?vue&type=script&lang=ts&"
export * from "./SiteFooter.vue?vue&type=script&lang=ts&"
import style0 from "./SiteFooter.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/tmp/build_96a94860/src/components/button/Button.vue').default,ScenkonstSite: require('/tmp/build_96a94860/src/components/navigation/ScenkonstSite.vue').default,SiteFooterContactList: require('/tmp/build_96a94860/src/components/navigation/SiteFooter/ContactList.vue').default,SocialMediaLinks: require('/tmp/build_96a94860/src/components/navigation/SocialMediaLinks.vue').default,SiteFooterSecondaryNav: require('/tmp/build_96a94860/src/components/navigation/SiteFooter/SecondaryNav.vue').default,SiteFooterPrimaryNav: require('/tmp/build_96a94860/src/components/navigation/SiteFooter/PrimaryNav.vue').default})
